<template>
<div class="dashboard">
  <form class="main" action @submit.prevent="openModal">
    <div :class="isMobile ? 'panel-mobile' : 'panel'">
        <span style="font-size: 28px; margin-bottom: 10px; text-align: center;">Welcome!</span>
        <span style="font-size: 14px; text-align: center;">Please fill out the form below. This information will only be used to verify your identity while taking assessments. We will never share or sell this information.</span>
        <div class="panel-title">Email & Phone Number</div>
        <div class="panel-body">
            <div class="panel-label">Email</div>
            <input id="email" type="email" style="margin-bottom: 25px;"
            placeholder="email@email.com" v-model="params.email" required>
            <div class="panel-label">Phone Number</div>
            <input type="tel" @input="acceptNumber" pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}" required placeholder="(555) 555-5555" v-model="phone">
        </div>
        <div class="panel-title">Security Question #1</div>
        <div class="panel-body">
            <Multiselect
          class="multiselect single"
          style="margin-bottom: 25px;"
          v-model="securityQuestion1"
          :options="availableSecurityQuestions1"
          track-by="id"
          label="text"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        ></Multiselect>
            <input placeholder="Type your response..." required v-model="answer1">
        </div>
        <div class="panel-title">Security Question #2</div>
        <div class="panel-body">
             <Multiselect
          class="multiselect single"
          style="margin-bottom: 25px;"
          v-model="securityQuestion2"
          :options="availableSecurityQuestions2"
          track-by="id"
          label="text"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        ></Multiselect>
            <input placeholder="Type your response..." required v-model="answer2">
        </div>
        <div class="panel-title">Security Question #3</div>
        <div class="panel-body">
            <Multiselect
          class="multiselect single"
          style="margin-bottom: 25px;"
          v-model="securityQuestion3"
          :options="availableSecurityQuestions3"
          track-by="id"
          label="text"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        ></Multiselect>
            <input placeholder="Type your response..." required v-model="answer3">
        </div>
        <button type="submit" :class="isMobile ? 'continue-mobile' : 'continue'" value="openModal">Continue</button>
    </div>
    </form>
    <modal class="modal-screens" v-show="isModalOpen">
      <template v-slot: header>
        <div>{{modalHeader}}</div>
      </template>
      <template v-slot:body class="body">
        <div :class="isMobile ? 'app-modal-mobile' : 'app-modal'">
          <div class="modal-header">Everything look ok?</div>
          <div class="modal-description">Once you submit these, you will not be able to change them without consulting your Hub administrator.</div>

          <div class="modal-flex">
          <div class="modal-div">
            <span class="label">Email</span>
            <span>{{ params.email }}</span>
          </div>
          <div class="modal-div">
            <span class="label">Phone Number</span>
            <span>{{ phone }}</span>
          </div>
          <div  class="modal-div" v-if="securityQuestion1">
            <span class="label">{{ securityQuestion1.text }}</span>
            <span>{{ answer1 }}</span>
          </div>
          <div class="modal-div" v-if="securityQuestion2">
            <span class="label">{{ securityQuestion2.text }}</span>
            <span>{{ answer2 }}</span>
          </div>
          <div class="modal-div" v-if="securityQuestion3" style="margin-bottom: 0;">
            <span class="label">{{ securityQuestion3.text }}</span>
            <span>{{ answer3 }}</span>
          </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-container">
        <button :class="isMobile ? 'confirm-mobile' : 'confirm'" @click="commit()">Confirm</button>
        <div style="font-family: VWText Bold; color: #00B0F0; cursor: pointer;" @click="closeModal()">Make Changes</div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Modal from '@/components/Modal.vue';

export default {
  name: 'welcome',
  components: {
    Multiselect,
    Modal,
  },
  data() {
    return {
      phone: null,
      securityQuestions: [],
      isModalOpen: false,
      securityQuestion1: [],
      securityQuestion2: [],
      securityQuestion3: [],
      answer1: null,
      answer2: null,
      answer3: null,
      params: {
        email: '',
        phone: '',
      },
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    commit() {
      const loader = this.$loading.show();
      this.params.security_questions = [
        {
          text: this.securityQuestion1.text,
          response: this.answer1,
        },
        {
          text: this.securityQuestion2.text,
          response: this.answer2,
        },
        {
          text: this.securityQuestion3.text,
          response: this.answer3,
        },
      ];
      this.$store.dispatch('updateUser', { userId: this.userInfo.id, params: this.params })
        .then(() => {
          this.$router.push({
            name: 'testerDashboard',
          });
          loader.hide();
        });
    },
    acceptNumber() {
      const x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
      this.params.phone = !x[2] ? x[1] : `${x[1]}${x[2]}${x[3] ? `${x[3]}` : ''}`;
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    loginInfo() {
      return this.$store.state.loginInfo;
    },
    isMobile() {
      if (this.$mq === 'mobile') {
        return true;
      }
      return false;
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    availableSecurityQuestions1() {
      if (this.securityQuestions) {
        let i = 0;
        const filteredQuestions = JSON.parse(JSON.stringify(this.securityQuestions));
        while (i < filteredQuestions.length) {
          if (filteredQuestions[i].id === this.securityQuestion2.id || filteredQuestions[i].id === this.securityQuestion3.id) {
            filteredQuestions.splice(i, 1);
          } else {
            i += 1;
          }
        }
        return filteredQuestions;
      }
      return null;
    },
    availableSecurityQuestions2() {
      if (this.securityQuestions) {
        let i = 0;
        const filteredQuestions = JSON.parse(JSON.stringify(this.securityQuestions));
        while (i < filteredQuestions.length) {
          if (filteredQuestions[i].id === this.securityQuestion1.id || filteredQuestions[i].id === this.securityQuestion3.id) {
            filteredQuestions.splice(i, 1);
          } else {
            i += 1;
          }
        }
        return filteredQuestions;
      }
      return null;
    },
    availableSecurityQuestions3() {
      if (this.securityQuestions) {
        let i = 0;
        const filteredQuestions = JSON.parse(JSON.stringify(this.securityQuestions));
        while (i < filteredQuestions.length) {
          if (filteredQuestions[i].id === this.securityQuestion1.id || filteredQuestions[i].id === this.securityQuestion2.id) {
            filteredQuestions.splice(i, 1);
          } else {
            i += 1;
          }
        }
        return filteredQuestions;
      }
      return null;
    },
  },
  created() {
    const loader = this.$loading.show();
    this.$store.dispatch('getSecurityQuestions')
      .then((res) => {
        this.securityQuestions = res;
        [this.securityQuestion1, this.securityQuestion2, this.securityQuestion3] = this.securityQuestions;
        loader.hide();
      });
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 8px 0px 8px 15px;
  box-sizing: border-box;
}
.blue{
  margin: 0;
  &:hover {
    margin: 0;
  }
}
.main {
  margin: 0px;
}
.panel {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 35px auto;
}
.panel-mobile {
  width: auto;
  padding: 0 40px;
  margin: 35px auto;
  display: flex;
  flex-direction: column;
  .panel-title{
    font-size: 20px;
  }
}
.panel-title {
  font-size: 28px;
  margin-bottom: 25px;
  margin-top: 35px;
}
.logo {
  margin: 0px 30px 0px 0px;
  height: 60px;
}
.header {
  background-color: #001E50;
  padding: 30px 35px;
  justify-content: start;
}
.header-text{
  font-family: 'VWText Bold';
  font-size: 28px;
  color: white;
}
.multiselect{
  width: 100% !important;
}
.label{
  font-family: "VWHead Bold";
  font-size: 10px;
  margin-bottom: 5px;
}
.app-modal{
    display: flex;
    flex-direction: column;
    width: 350px;
}
.app-modal-mobile{
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
    @media only screen and (max-width: 400px) {
    width: 250px;
    min-width: 250px;
  }
    .modal-flex {
      padding: 0;
    }
}
.modal-header{
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}
.modal-description{
    text-align: center;
    color: #FF335C;
    font-family: "VWHead bold";
    font-size: 14px;
    margin-bottom: 27px;
}
.modal-flex {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
.modal-div {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.continue {
  width: 120px;
  margin-top: 35px;
}
.continue-mobile{
  width: 100%;
  margin-top: 35px;
}
.confirm {
  margin-bottom: 10px;
  width: 125px;
}
.confirm-mobile {
  width: 100%;
  margin-bottom: 10px;
}
.footer-container{
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
