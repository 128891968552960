<template>
<div class="main">
    <div class="success-container">
        <checkIcn style="width: 50px; margin-bottom: 15px;"></checkIcn>
        <span style="font-size: 20px; margin-bottom: 25px;">Assessment saved successfully!</span>
        <div style="display: flex;">
        <button style="width: 180px; margin-right: 15px;" @click="goToCreate()">Create Another</button>
        <button style="width: 180px;" @click="goToEdit()">Go Back and Edit</button>
        </div>
    </div>
</div>
</template>

<script>
import checkIcn from '@/assets/icons/checkmark_icon.svg';

export default {
  name: 'success',
  components: {
    checkIcn,
  },
  methods: {
    goToCreate() {
      this.$router.push({
        name: 'create',
      });
    },
    goToEdit() {
      this.$router.push({
        name: 'edit',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main {
    margin: 35px 60px 0 60px;
    height: 100%;
}
</style>
