<template>
  <div class="dashboard">
    <div class="main" v-if="incompleteAssessments">
      <div class="table-header">
        <div style="font-size: 20px; color: black;"> Incomplete Assessments
        </div>
      </div>
      <div class="table-container" style='margin-bottom: 40px;'>
        <data-table
        :headings="incompleteTableHeaders"
        :rows="incompleteAssessments"
        :perPage="rowCount"
        :page="incPageNumber"
        >
        <template v-slot:time_limitSlot="slotProps">
          <div> {{ slotProps.row.time_limit }} hours</div>
        </template>
        <template v-slot:deadline_dateSlot="slotProps">
          <div :class="withinAWeek(slotProps.row.deadline_date) ? 'red' : 'not-red'">{{ slotProps.row.deadline_date | date }}</div>
        </template>
        <template v-slot:actionsSlot="slotProps">
            <div v-if="!slotProps.row.inProgress" @click="openModal(slotProps.row)" style="color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;">Begin</div>
            <div v-if="slotProps.row.inProgress" v-tooltip="{
              content: 'This assessment is in progress. Please contact your administrator to retake',
              }" style="color: #B6BFC5; cursor: default !important; font-family: 'VWText Bold';">Invalid</div>
        </template>
        </data-table>
      </div>
      <div class="table-header">
        <div style="font-size: 20px; color: black;"> Completed Assessments
        </div>
      </div>
      <div class="table-container">
         <data-table
        :headings="completedTableHeaders"
        :rows="completedAssessments"
        :perPage="rowCount"
        :page="comPageNumber"
        >
        <template v-slot:scoreSlot="slotProps">
          <div style="font-family: 'VWText Bold" v-if="slotProps.row.total_questions > 0">
            {{ Math.floor(slotProps.row.correct_answers / slotProps.row.total_questions * 100) }}%
          </div>
          <div style="font-family: 'VWText Bold" v-if="slotProps.row.total_questions === 0">Incomplete</div>
        </template>
         </data-table>
      </div>
    </div>
    <modal class="modal-screens" v-show="isModalOpen">
      <template v-slot: header>
        <div>{{modalHeader}}</div>
      </template>
      <template v-slot:body class="body">
        <div :class="isMobile ? 'app-modal-mobile' : 'app-modal'">
          <div style="font-size: 24px; text-align: center;">This is a {{ assessmentLength }}-question assessment.</div>
          <div v-if="timeLimit" style="font-size: 24px; text-align: center; margin-bottom: 20px;">It will take about {{ timeLimit }} hours to complete.</div>
          <div style="color: #FF335C; font-family: 'VWText Bold'; text-align: center;">Once you begin, you must complete the entire assessment.</div>
          <div style="color: #FF335C; font-family: 'VWText Bold'; text-align: center;">You will not be able to come back and finish later.</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display: flex; flex-direction: column;">
        <button @click="commit()" style="margin: 0 0 20px 0;">I Understand—Continue</button>
        <div style="text-align: center; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="closeModal()">Cancel</div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import DataTable from '@/components/DataTable.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'testerDashboard',
  components: {
    DataTable,
    Modal,
  },
  data() {
    return {
      recentAssessments: [],
      recentTesters: [],
      userAssessments: [],
      rowCount: 1000,
      comPageNumber: 1,
      incPageNumber: 1,
      toggleColor: '#00B0F0',
      isModalOpen: false,
      modalHeader: 'CONFIRM',
      selectedRow: null,
      loading: this.$loading.show(),
      incompleteAssessments: [],
      completedAssessments: [],
      timeLimit: '',
      assessmentLength: null,
      selectedAssessment: null,
    };
  },
  methods: {
    getUser() {
      const loader = this.$loading.show();
      this.$store.dispatch('getUser', { loginInfo: this.loginInfo })
        .then((res) => {
          if (res && res.email !== '' && res.email !== null) {
            loader.hide();
            this.$router.push({ name: 'testerDashboard' });
          } else {
            loader.hide();
            this.$router.push({ name: 'welcome' });
          }
        });
    },
    getUserResults() {
      this.$store.dispatch('getUserResults', { userId: this.user.id })
        .then((res) => {
          if (res.length > 0) {
            this.completedAssessments = res;
            this.getAssessmentByUser();
          } else {
            this.getAssessmentByUser();
          }
        })
        .catch(() => {
          this.$notify({
            text: 'Completed Data Not Retrieved',
            group: 'primary',
            type: 'error',
          });
          this.loading.hide();
        });
    },
    getAssessmentByUser() {
      this.$store.dispatch('getAssessmentByUser', { userId: this.user.id })
        .then((res) => {
          if (res.length > 0) {
            this.userAssessments = res;
            for (let i = 0; i < this.userAssessments.length; i += 1) {
              for (let k = 0; k < this.completedAssessments.length; k += 1) {
                if (this.userAssessments[i].id === this.completedAssessments[k].assessment_instance_id) {
                  this.userAssessments[i].completed = true;
                } else {
                  this.userAssessments[i].completed = false;
                }
              }
            }
            this.getAssessments();
          } else {
            this.getAssessments();
          }
        })
        .catch(() => {
          this.$notify({
            text: 'Completed Data Not Retrieved',
            group: 'primary',
            type: 'error',
          });
          this.loading.hide();
        });
    },
    getAssessments() {
      this.$store.dispatch('getAssessmentsByJobRole', { jobRole: this.user.job_role })
        .then((res) => {
          const newArray = JSON.parse(JSON.stringify(res));
          if (res.length > 0) {
            for (let i = 0; i < res.length; i += 1) {
              if (res[i].status === '1') {
                if (this.userAssessments.length === 0) {
                  this.incompleteAssessments.push(res[i]);
                }
                for (let j = 0; j < this.userAssessments.length; j += 1) {
                  if (res[i].id === this.userAssessments[j].assessment_id) {
                    if (this.userAssessments[j].allow_retake !== 0 || !this.userAssessments[j].start_time || (this.userAssessments[j].start_time
                    && !this.userAssessments[j].completed && !this.userAssessments[j].end_time) || (this.userAssessments[j].start_time
                    && !this.userAssessments[j].completed && this.userAssessments[j].end_time)) {
                      newArray[i].inProgress = true;
                      newArray[i].allow = true;
                    } else {
                      newArray[i].disallow = true;
                    }
                    if (this.userAssessments[j].allow_retake !== 0) {
                      newArray[i].inProgress = false;
                    }
                  } else if (!newArray[i].disallow) {
                    newArray[i].allow = true;
                  }
                }
              }
              if (newArray[i].allow && !newArray[i].disallow) {
                this.incompleteAssessments.push(newArray[i]);
                this.forceRefresh();
              }
            }
          }
          this.loading.hide();
        })
        .catch(() => {
          this.$notify({
            text: 'Incomplete Data Not Retrieved',
            group: 'primary',
            type: 'error',
          });
          this.loading.hide();
        });
    },
    getData() {
      this.getUserResults();
    },
    openModal(row) {
      this.timeLimit = row.time_limit;
      this.assessmentLength = row.total_questions;
      this.isModalOpen = true;
      this.selectedAssessment = row;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getAssessmentInstance(assessment) {
      const loader = this.$loading.show();
      this.$store.dispatch('getAssessmentInstance', { assessmentID: assessment.insertId })
        .then((res) => {
          this.$store.commit('SET_SELECTED_ASSESSMENT', res);
          this.$router.push({ name: 'testerAssessment' });
          loader.hide();
        })
        .catch(() => {
          this.$notify({
            text: 'Error getting assessment',
            classes: 'vue-notification',
            type: 'error',
          });
          loader.hide();
        });
    },
    commit() {
      const params = {
        user_id: this.user.id,
        assessment_id: this.selectedAssessment.id,
      };
      this.$store.dispatch('createAssessmentInstance', params)
        .then((res) => {
          this.getAssessmentInstance(res.assessmentInstance);
          this.$store.commit('SET_SECURITY_FEATURES', res.enabledFeatures);
        })
        .catch(() => {
          this.$notify({
            text: 'Assessment could not be retrieved',
            classes: 'vue-notification',
            type: 'error',
          });
        });
    },
    withinAWeek(dateString) {
      const REFERENCE = moment(new Date());
      const A_WEEK_OLD = REFERENCE.clone().add(7, 'days').startOf('day');

      function isWithinAWeek(momentDate) {
        return momentDate.isBefore(A_WEEK_OLD);
      }
      const date = new Date(dateString);
      if (date) {
        if (isWithinAWeek(moment(date))) {
          return true;
        }

        return false;
      }
      return dateString;
    },
    forceRefresh() {
      this.$forceUpdate();
    },
  },
  computed: {
    incompleteTableHeaders() {
      if (this.isMobile) {
        return this.$store.state.mobileIncompleteTableHeaders;
      }
      return this.$store.state.incompleteTableHeaders;
    },
    completedTableHeaders() {
      if (this.isMobile) {
        return this.$store.state.mobileCompletedTableHeaders;
      }
      return this.$store.state.completedTableHeaders;
    },
    user() {
      return this.$store.state.user;
    },
    isMobile() {
      if (this.$mq === 'mobile') {
        return true;
      }
      return false;
    },
    loginInfo() {
      return this.$store.state.loginInfo;
    },
  },
  created() {
    this.getUserResults();
    this.getUser();
  },
};

</script>

<style lang="scss" scoped>
.header-middle {
  display: flex;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.edit-blue {
color: #00B0F0;
cursor: pointer;
}

.edit-gray {
color: #B6BFC5;
cursor: default;
}

.app-modal {
  width: 430px;
}

.red {
 color: red;
}

</style>
