<template>
  <div class="dashboard">
          <!--User Acknowledgement HTML-->
      <div class="main" v-if="!userAcknowledgement && currentAssessment && !thankYou ">
          <div :class="isMobile ? 'title-mobile' : 'title'">{{ currentAssessment.name }}</div>
          <div v-if="testPanel === 'verifyRequirements'" :class="isMobile ? 'panel-mobile' : 'panel'">
              <div style="text-align: left; width: 100%; margin-bottom: 30px;">To take this assessment, you must meet the following requirements:</div>
              <div v-if="facialRecognition" class="panel-row" style="justify-content: left; margin-bottom: 15px;">
                <checkbox v-if="cameraAccess" class="checked"/>
                <xicon v-if="!cameraAccess" class="checked" @click="cameraToggle()"/>
                <span>Allow camera access</span>
              </div>
              <div class="panel-row" style="margin-bottom: 15px; justify-content: left" v-if="ipAddressAuth">
                <checkbox v-if="locationAccess" class="checked"/>
                <xicon v-if="!locationAccess" class="checked" @click="locationToggle()"/>
                <span>Allow location access</span>
              </div>
              <div class="panel-row" style="margin-bottom: 15px; justify-content: left">
                <div>
                <checkbox v-if="browserCompatibility" class="checked"/>
                <xicon v-if="!browserCompatibility" class="checked" @click="browserToggle()"/>
                </div>
                <div style="text-align: left;">Most recent version of IE/Edge, Firefox, Chrome or Safari</div>
              </div>
              <button :disabled="!allToggled" style="width: 150px; margin: 0;" @click="submitVerification()">Confirm</button>
              <div style="margin-top: 20px; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="goToDashboard()">Cancel</div>
          </div>
          <div v-show="testPanel === 'getPhoto'" :class="isMobile ? 'panel-mobile' : 'panel'">
              <span style="margin-bottom: 20px;">For security purposes, we'll need a photograph of you before we begin the assessment.</span>
              <button v-if="!sourceImage" style="width: 200px; margin: 0" @click="onCaptureSource()">Take Picture</button>
              <button v-if="sourceImage" style="width: 200px; margin: 0" @click="sourceImage = null">Retake</button>
              <WebCam v-show="!this.sourceImage"
              style="margin: 35px 0;"
              ref="webcam"
              width="100%"
              height="225px"
              @started="onStarted"
              :isFrontCam="true"
              ></WebCam>
              <img :src="this.sourceImage" v-if="sourceImage" style="width: 400px; height: auto; margin: 35px 0;">
              <button :disabled="!sourceImage" style="width: 200px; margin: 0;" @click="submitSourcePhoto()">Submit</button>
              <div style="margin-top: 20px; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="goToDashboard()">Cancel</div>
          </div>
          <div v-if="testPanel === 'getCode'" :class="isMobile ? 'panel-mobile' : 'panel'">
              <div class="panel-row" style="margin-bottom: 25px; text-align: left;">Finally, we need to send you a code to verify your identity:</div>
              <div class="panel-column" style="text-align: left; margin-bottom: 20px; margin-left: 15px;">
                <div class="panel-row" style="margin-bottom: 15px; justify-content: left;">
                  <button
                :class="authType === 'email' ? 'radio-selected' : 'radio'"
                @click="authType = 'email'"
              >
                <div v-if="authType === 'email'" class="selected"></div>
              </button>
                  <span>Email ({{ user.email | emailHide }})</span>
                </div>
                <div class="panel-row" style="justify-content: left;">
                  <button
                :class="authType === 'phone' ? 'radio-selected' : 'radio'"
                @click="authType = 'phone'"
              >
                <div v-if="authType === 'phone'" class="selected"></div>
              </button>
                  <span>Text ({{ user.phone | phoneHide }})</span>
                </div>
              </div>
              <button style="width: 200px; margin: 0;" @click="submitGetCode()">Send Code</button>
              <div style="margin-top: 20px; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="goToDashboard()">Cancel</div>
          </div>
          <form v-if="testPanel === 'enterCode'" :class="isMobile ? 'panel-mobile' : 'panel'" action @submit.prevent="submitAuthCode">
              <span style="margin-bottom: 20px;">Enter the 4-digit code we just sent you:</span>
              <div class="panel-row" style="justify-content: center;">
                <input @input="$event.target.nextElementSibling.focus()" required minlength="1" maxlength="1" class="code-input" v-model="code1"/>
                <input @input="$event.target.nextElementSibling.focus()" required minlength="1" maxlength="1" class="code-input" v-model="code2"/>
                <input @input="$event.target.nextElementSibling.focus()" required minlength="1" maxlength="1" class="code-input" v-model="code3"/>
                <input required minlength="1" maxlength="1" class="code-input" v-model="code4" style="margin-right: 0px;"/>
              </div>
              <button style="width: 200px; margin: 0;" value="submitAuthCode">Continue</button>
              <div style="margin-top: 20px; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="goToDashboard()">Cancel</div>
          </form>
          <div v-if="testPanel === 'acknowledgement'" :class="isMobile ? 'panel-mobile' : 'panel'">
              <span>You're now ready to begin the assessment</span>
              <span style="margin-bottom: 20px; max-width: 450px;">Please read the following very carefully:</span>
              <span style="margin-bottom: 20px; color: #FF335C; font-family: 'VWText Bold'; max-width: 450px;">Once you begin, you must complete the entire assessment. You will not be able to come back and finish later.</span>
              <span style="margin-bottom: 20px; color: #FF335C; font-family: 'VWText Bold'; max-width: 450px;">You will not be able to go back and revisit questions you've already answered.</span>
              <span style="margin-bottom: 35px; color: #FF335C; font-family: 'VWText Bold'; max-width: 450px;">At some point during the assessment, you may be asked to verify your identity using the email or phone number you provided.</span>
              <button style="width: 205px; margin: 0;" @click="beginAssessment()">I Understand—Begin</button>
              <div style="margin-top: 20px; color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="goToDashboard()">Cancel</div>
          </div>
      </div>
        <!--Thank You HTML-->
      <div class="main" style="margin-top: 50px;" v-if="userAcknowledgement && currentAssessment && thankYou ">
          <div :class="isMobile ? 'panel-mobile' : 'panel'">
              <span style="font-size: 20px; margin-bottom: 20px;">Thank you!</span>
              <span style="margin-bottom: 35px; max-width: 450px;">Your responses have been recorded. You will find out the status of your certification in a few weeks.</span>
              <button :style="isMobile ? 'width: 100%; margin: 0' : 'width: 140px; margin: 0'" @click="goToDashboard()">Close</button>
          </div>
      </div>
      <!--User Taking Test HTML-->
    <div class="main" v-if="currentAssessment && userAcknowledgement && !thankYou && questions">
      <div class="title-container" v-if="userAcknowledgement && !isMobile">
        <span>{{ currentAssessment.name }}</span>
        <span style="margin: 0 20px; border-right: 1px solid #000000; height: 20px;"></span>
        <span style="font-size: 14px !important;">
          Time Elapsed:
          <span style="font-family: VWHead Bold; color: #00B0F0">{{ timeDisplay }}</span>
        </span>
      </div>
      <div class="title-container-mobile" v-if="userAcknowledgement && isMobile">
        <div>
           <span>{{ currentAssessment.name }}</span>
        </div>
       <div>
         <span style="font-size: 14px !important;">
          Time Elapsed:
          <span style="font-family: VWHead Bold; color: #00B0F0">{{ timeDisplay }}</span>
        </span>
       </div>
      </div>
      <div class="body">
        <div class="panel test" style="box-shadow: none;">
          <div
            class="panel-label"
          >Question {{ currentQuestionNumber + 1 }}/{{ questions.length }}</div>
          <div class="panel-body" style="min-width: 250px;">
            <div class="panel-row">
              <div class="panel-column">
                <span style="font-size: 20px; text-align: left;">{{ activeQuestion.text }}</span>
              </div>
            </div>
            <div
              v-for="(answer, index) in activeQuestion.answers"
              :key="answer.id"
              :class="{'panel-row':true, last : index === (activeQuestion.answers.length-1)}"
            >
              <button
                :class="answer.selected === 1 ? 'radio-selected' : 'radio'"
                @click="selectAnswer(index)"
              >
                <div v-if="answer.selected === 1" class="selected"></div>
              </button>
              <span style="font-family:'VWText Bold'">{{alphabetArray[index]}}.</span>
              <span style="margin-left: 5px;">{{answer.text}}</span>
            </div>
          </div>
          <div :style="isMobile ? 'display: flex; margin: 0 35px' : ''">
          <button
            v-if="currentQuestionNumber < questions.length - 1"
            :class="answer ? '' : 'alt-btn'" :style="isMobile ? 'width: 100%;' : 'width: 170px'"
            @click="submitAnswer()"
          >Next Question</button>
          <button
            v-if="currentQuestionNumber === questions.length - 1"
            :class="answer ? '' : 'alt-btn'" :style="isMobile ? 'width: 100%' : 'width: 140px'"
            @click="finishAssessment()"
          >Finish</button>
          </div>
        </div>
      </div>
      <WebCam v-if="currentAssessment.facial_recognition === 1" v-show="false"
              style="margin: 20px 0;"
              ref="webcam"
              width="100%"
              height="100%"
              :isFrontCam="true"
              ></WebCam>
    </div>
    <modal class="modal-screens" v-show="isModalOpen">
      <template v-slot: header>
        <div>{{modalHeader}}</div>
      </template>
      <template v-slot:body class="body">
        <div v-if="modalType== 'question'" :class="isMobile ? 'app-modal-mobile' : 'app-modal'">
          <div class="modal-header" style="margin-bottom: 30px;">Please answer the following security question.</div>
          <div class="modal-description" style="margin-bottom: 10px;">{{ securityQuestion }}</div>
          <div class="modal-flex">
          <div class="modal-div">
            <input placeholder="Answer here" v-model="securityAnswer" class="label" required>
          </div>
          </div>
        </div>
        <div v-if="modalType== 'beforeLeave'" :class="isMobile ? 'app-modal-mobile' : 'app-modal'">
          <h3>You are about to exit the current assessment. This will void any unanswered questions.<br> Would you like to continue to leave the assessment? </h3>
        </div>
         <div v-if="modalType== 'timeoutModal'" :class="isMobile ? 'app-modal-mobile' : 'app-modal'">
          <h3>Your session will end in 2 minutes due to inactivity.<br>Please choose OK to remain logged in.</h3>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-container">
        <button v-if="modalType =='question'" :disabled = !securityAnswer :class="isMobile ? 'confirm-mobile' : 'confirm'" @click="commit()">Confirm</button>
        <button v-if="modalType =='timeoutModal'" :class="isMobile ? 'confirm-mobile' : 'confirm'" @click="resetInterval()">OK</button>
        <div style="display: flex;" v-if="modalType=='beforeLeave'">
        <button style="margin-right: 10px;" @click="nav()">Yes</button>
        <button @click="closeModal()">No</button>
        </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { WebCam } from 'vue-cam-vision';
import checkbox from '@/assets/icons/checkbox.svg';
import xicon from '@/assets/icons/x_icon.svg';
import Modal from '@/components/Modal.vue';

export default {
  name: 'testerAssessment',
  components: {
    checkbox,
    xicon,
    WebCam,
    Modal,
  },
  data() {
    return {
      currentQuestionNumber: 0,
      time: 0,
      isRunning: false,
      cameraInterval: null,
      tokenInterval: null,
      userAcknowledgement: false,
      newAssessment: null,
      loading: this.$loading.show(),
      thankYou: false,
      questions: null,
      answer: null,
      testPanel: 'verifyRequirements',
      cameraAccess: false,
      locationAccess: false,
      browserCompatibility: false,
      authType: 'email',
      frontCam: false,
      sourceImage: null,
      currentImage: null,
      camera: null,
      deviceId: null,
      devices: [],
      securityAnswer: null,
      securityQuestion: null,
      isModalOpen: false,
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      confirmNav: false,
      navTo: null,
      modalType: null,
      timeoutInterval: null,
      logoutInterval: null,
    };
  },
  methods: {
    getAssessment() {
      this.$store.dispatch('getAssessmentQuestions', { assessmentID: this.currentAssessment.id })
        .then((res) => {
          if (res) {
            this.questions = res;
            this.userAcknowledgement = !this.userAcknowledgement;
          }
        });
    },
    beginAssessment() {
      this.$store.dispatch('startAssessmentInstance', { assessmentID: this.currentAssessment.id })
        .then(() => {
          this.toggleTimer();
          this.getAssessment();
          if (this.sourceImage) {
            this.cameraInterval = window.setInterval(() => {
              this.onCaptureCurrent();
            }, 300000);
          }
          this.resetInterval();
        });
    },
    logout() {
      this.confirmNav = true;
      this.thankYou = true;
      window.location.href = 'https://vwgoalogin.track360.com/logout.php';
    },
    resetInterval() {
      if (this.timeoutInterval && this.logoutInterval) {
        clearInterval(this.timeoutInterval);
        clearInterval(this.logoutInterval);
      }
      this.$store.dispatch('refresh');
      this.closeModal();

      this.timeoutInterval = window.setInterval(() => {
        this.modalType = 'timeoutModal';
        this.openModal();
      }, 780000);
      this.logoutInterval = window.setInterval(() => {
        this.logout();
      }, 900000);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    commit() {
      const loader = this.$loading.show();
      this.$store.dispatch('securityQuestionResponse', { assessmentID: this.currentAssessment.id, answer: this.securityAnswer })
        .then(() => {
          this.securityAnswer = null;
          this.closeModal();
          this.currentQuestionNumber += 1;
          this.resetInterval();
          loader.hide();
        });
    },
    submitAnswer() {
      if (this.answer) {
        const params = {
          user_id: this.user.id,
          question_instance_id: this.activeQuestion.id,
          answer_instance_id: this.answer,
        };
        const loader = this.$loading.show();
        this.$store.dispatch('submitAnswer', { assessmentID: this.currentAssessment.id, params })
          .then((res) => {
            if (res.securityQuestion) {
              this.securityQuestion = res.securityQuestion.text;
              this.modalType = 'question';
              this.answer = null;
              this.openModal();
            } else {
              this.currentQuestionNumber += 1;
              this.answer = null;
              this.resetInterval();
            }
            loader.hide();
          })
          .catch(() => {
            loader.hide();
          });
      }
    },
    onStarted() {
      this.cameraAccess = true;
    },
    selectAnswer(answer) {
      for (let i = 0; i < this.activeQuestion.answers.length; i += 1) {
        if (this.activeQuestion.answers[i] === this.activeQuestion.answers[answer]) {
          this.activeQuestion.answers[i].selected = 1;
          this.answer = this.activeQuestion.answers[i].id;
          this.$forceUpdate();
        } else {
          this.activeQuestion.answers[i].selected = 0;
          this.$forceUpdate();
        }
      }
    },
    finishAssessment() {
      if (this.$refs.webcam) {
        this.onStop();
      }
      if (this.answer) {
        const params = {
          user_id: this.user.id,
          question_instance_id: this.activeQuestion.id,
          answer_instance_id: this.answer,
        };
        this.$store.dispatch('submitAnswer', { assessmentID: this.currentAssessment.id, params })
          .then(() => {
            this.submitAssessment();
          })
          .catch(() => {
            this.$notify({
              text: 'Answer did not submit',
              classes: 'vue-notification',
              type: 'error',
            });
          });
      }
    },
    submitAssessment() {
      const params = {
        user_id: this.user.id,
      };
      this.$store.dispatch('submitAssessment', { assessmentID: this.currentAssessment.id, params })
        .then(() => {
          this.thankYou = !this.thankYou;
        })
        .catch(() => {
          this.$notify({
            text: 'Assessment did not submit',
            classes: 'vue-notification',
            type: 'error',
          });
        });
    },
    submitVerification() {
      if (this.facialRecognition) {
        this.testPanel = 'getPhoto';
      } else if (this.phoneEmailAuth) {
        this.testPanel = 'getCode';
      } else {
        this.testPanel = 'acknowledgement';
      }
    },
    submitSourcePhoto() {
      const loader = this.$loading.show();
      this.$store.dispatch('sourceImage', { assessmentID: this.currentAssessment.id, file: this.sourceImage })
        .then(() => {
          if (this.phoneEmailAuth) {
            this.testPanel = 'getCode';
          } else {
            this.testPanel = 'acknowledgement';
          }
          this.resetInterval();
          loader.hide();
        });
    },
    submitCurrentPhoto() {
      this.$store.dispatch('currentImage', { assessmentID: this.currentAssessment.id, file: this.currentImage })
        .then(() => {
          this.resetInterval();
          this.$store.dispatch('imageComparisons', { assessmentID: this.currentAssessment.id });
          this.currentImage = null;
        });
    },
    submitGetCode() {
      const loader = this.$loading.show();
      this.$store.dispatch('receiveAuthCode', { authType: this.authType, assessmentID: this.currentAssessment.id })
        .then(() => {
          this.resetInterval();
          this.testPanel = 'enterCode';
          loader.hide();
        });
    },
    submitAuthCode() {
      const loader = this.$loading.show();
      this.$store.dispatch('submitAuthCode', { code: this.code, assessmentID: this.currentAssessment.id })
        .then(() => {
          this.testPanel = 'acknowledgement';
          this.resetInterval();
          loader.hide();
        })
        .catch(() => {
          this.$notify({
            text: 'Incorrect Authorization Code Submitted.',
            group: 'primary',
            type: 'error',
          });
          loader.hide();
        });
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      // eslint-disable-next-line radix
      this.time = parseInt(this.time) + 1;
    },
    goToDashboard() {
      if (this.$refs.webcam) {
        this.$refs.webcam.stop();
      }
      this.$router.push({
        name: 'testerDashboard',
      });
    },
    locationToggle() {
      this.locationAccess = true;
    },
    browserToggle() {
      this.browserCompatibility = true;
    },
    async onCaptureSource() {
      if (this.$refs.webcam) {
        this.sourceImage = await this.$refs.webcam.capture();
      }
    },
    async onCaptureCurrent() {
      if (this.$refs.webcam) {
        this.currentImage = await this.$refs.webcam.capture();
        if (this.currentImage) {
          this.submitCurrentPhoto();
        }
      }
    },
    onStop() {
      this.sourceImage = null;
      this.currentImage = null;
      this.$refs.webcam.stop();
    },
    nav() {
      if (this.$refs.webcam) {
        this.$refs.webcam.stop();
      }
      this.confirmNav = true;
      this.$router.push({
        name: this.navTo,
      });
    },
    preventNav(event) {
      if (this.thankYou && this.confirmNav) {
        return;
      }
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
  },
  computed: {
    isMobile() {
      if (this.$mq === 'mobile') {
        return true;
      }
      return false;
    },
    activeQuestion() {
      if (this.questions) {
        return JSON.parse(
          JSON.stringify(this.questions[this.currentQuestionNumber]),
        );
      }

      return null;
    },
    currentAssessment() {
      return this.$store.state.assessmentSelected[0];
    },
    user() {
      return this.$store.state.user;
    },
    validateRequirements() {
      return true;
    },
    code() {
      if (this.code1 && this.code2 && this.code3 && this.code4) {
        return this.code1.toString() + this.code2.toString() + this.code3.toString() + this.code4.toString();
      }
      return null;
    },
    timeDisplay() {
      if (this.time) {
        const hours = this.time / 3600;
        let rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        let rminutes = Math.floor(minutes);
        let seconds = this.time % 60;
        if (rhours > 10) {
          rhours = `0${rhours}`;
        }
        if (rminutes < 10) {
          rminutes = `0${rminutes}`;
        }
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }
        if (rhours > 0) {
          return `${rhours}:${rminutes}:${seconds}`;
        }
        return `${rminutes}:${seconds}`;
      }
      return null;
    },
    facialRecognition() {
      if (this.currentAssessment) {
        if (this.currentAssessment.facial_recognition === 1) {
          return true;
        }
      }
      return false;
    },
    phoneEmailAuth() {
      if (this.currentAssessment) {
        if (this.currentAssessment.phone_email_auth === 1) {
          return true;
        }
      }
      return false;
    },
    ipAddressAuth() {
      if (this.currentAssessment) {
        if (this.currentAssessment.ip_monitoring === 1) {
          return true;
        }
      }
      return false;
    },
    allToggled() {
      if (this.cameraAccess && this.locationAccess && this.browserCompatibility) {
        return true;
      }
      return false;
    },
    alphabetArray() {
      return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    },
  },
  created() {
    this.loading.hide();
    if (this.currentAssessment.ip_monitoring !== 1) {
      this.locationAccess = true;
    }
    if (this.currentAssessment.facial_recognition !== 1) {
      this.cameraAccess = true;
    }
    this.timeoutInterval = window.setInterval(() => {
      this.modalType = 'timeoutModal';
      this.openModal();
    }, 780000);
    this.logoutInterval = window.setInterval(() => {
      this.logout();
    }, 900000);
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    clearInterval(this.cameraInterval);
    clearInterval(this.timeoutInterval);
    clearInterval(this.logoutInterval);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.thankYou && !this.confirmNav) {
      this.openModal();
      this.modalType = 'beforeLeave';
      this.navTo = to.name;
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin: 0px;
}
.header {
  background-color: #001e50;
  justify-content: space-between;
  padding: 35px 60px;
}
.header-text {
  font-family: "VWText Bold";
  font-size: 14px;
  color: white;
}
.title-container {
  display: flex;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #ffffff;
  align-items: center;
  border-bottom: 1px solid #dfe4e8;
}
.title-container-mobile{
  display: flex;
  flex-direction: column;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #dfe4e8;
  text-align: left;
}

.title {
  text-align: center;
  font-size: 28px;
  margin: 35px 0;
}

.title-mobile {
  text-align: center;
  font-size: 28px;
  margin: 35px 35px;
}

.panel {
   margin: 0 auto;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 14px;
  width: 475px;
  background-color: white;
  .panel-label {
    font-size: 12px;
    margin-bottom: 15px;
    @media only screen and (max-width: 700px) {
    margin: 0 35px 15px 35px
  }
  }
  box-shadow: 0px 2px 6px #0000001A;
}
.last {
  margin-bottom: 0px !important;
}

.panel-mobile {
  margin: 35px 35px 0px 35px;
  padding: 35px 45px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 14px;
  width: auto;
  background-color: white;
  .panel-label {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .panel-row {
    justify-content: center;
  }
  box-shadow: 0px 2px 6px #0000001A;
}
.last {
  margin-bottom: 0px !important;
}

.checked {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  cursor: pointer;
}

.code-input{
  height: 60px;
  margin-right: 10px;
  font-size: 40px;
  text-align: center;
  width: 60px;
  @media only screen and (max-width: 700px) {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
}

.body {
  display: flex;
}

.test{
  width: 100%;
  padding: 30px 200px;
  background: transparent;
  align-items: start;
  @media only screen and (max-width: 700px) {
    width: auto;
    padding: 20px 0;
  }
  .panel-body {
    width: 100%;
    margin-bottom: 35px;
    @media only screen and (max-width: 700px) {
    width: auto;
    margin: 0 35px 35px 35px;
  }
  }
}

</style>
