<template>
  <div class="dashboard">
    <div class="main">
      <div class="above-table">
        <div class="filter">
          <span style="position: absolute; top: 10px; left: 10px;">Filter</span>
          <carrot style="position: absolute; right: 10px; top: 15px; height: 10px; transition: all 200ms;" :class="filterToggle ? 'carrot' : 'carrot-flipped'"></carrot>
          <div style="position: absolute; width: 200px; height: 40px;" @click="filterToggle = !filterToggle"></div>
          <div class="filter-box" v-if="filterToggle" v-click-outside="clearFilters">
            <div style="display: flex; flex-direction: column; width: 100%;">
              <div>
                <div style="font-family: 'VWHead Bold'; font-size: 12px; margin-bottom:5px;">Assessment</div>
              <Multiselect
                class="multiselect"
                style="width: 300px !important;"
                v-model="assessmentFilterValue"
                :options="assessmentList"
                placeholder="Sample Assessment Name"
                :show-labels="false"
                :searchable="true"
                :allow-empty="true"
              >{{filterValue}}
              </Multiselect>
              </div>
              <div style="margin-top: 25px;">
              <Multiselect
                class="multiselect"
                style="width: 200px !important;"
                v-model="dealerCodeFilterValue"
                :options="dealerCodeList"
                placeholder="Dealer Code"
                :show-labels="false"
                :searchable="true"
                :allow-empty="true"
              >{{filterValue}}
              </Multiselect>
              </div>
               <div style="margin-top: 25px;">
              <Multiselect
                class="multiselect multiple"
                style="width: 100% !important;"
                v-model="jobCodeFilterValue"
                :options="jobCodes"
                track-by="id"
                label="name"
                placeholder="Filter by Job Role"
                :show-labels="false"
                :multiple="true"
                :close-on-select="false"
                :searchable="true"
                :allow-empty="true"
                :limit="5"
              >{{jobCodeFilterValue}}
              </Multiselect>
              <div style="display: flex; margin-top: 45px;">
              <button style="margin-right: 15px;" @click="applyFilters()">Apply</button>
              <button @click="clearFilters()">Clear</button>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="float-right">
          <Magnifier class="search-icn"></Magnifier>
          <input class="search" placeholder="Search results" v-model="searchQuery" type="search"/>
           <Multiselect
          class="multiselect rowCount"
          v-model="rowCountDropValue"
          :options="rowOptions"
          track-by="label"
          label="label"
          :showLabels="false"
          :allow-empty="false"
          :searchable="false"
        >{{filterValue}}</Multiselect>
        </div>
      </div>
      <div class="table-container" style='margin-bottom: 40px;'>
        <data-table
        :headings="resultsTableHeaders"
        :rows="results"
        :perPage="rowCountDropValue.value"
        :page="currentPage"
        :search-query="searchQuery"
        :resultsFilters="filters"
        :clickableRows="true"
        tableName="results"
        >
        <template v-slot:completedSlot="slotProps">
          <div v-if="slotProps.row" style="color: #00E6E6; font-family: 'VWText Bold">Yes</div>
        </template>
        <template v-slot:scoreSlot="slotProps">
          <div style="font-family: 'VWText Bold" v-if="slotProps.row.total_questions > 0">
            {{ Math.floor(slotProps.row.correct_answers / slotProps.row.total_questions * 100) }}%
          </div>
           <div style="font-family: 'VWText Bold" v-if="slotProps.row.total_questions === 0">Incomplete</div>
        </template>
        <template v-slot:suspicionSlot="slotProps">
          <div v-if="slotProps.row.enabled_security_features" style="font-family: 'VWText Bold">
            {{ slotProps.row.flagged_security_features }} / {{ slotProps.row.enabled_security_features }}
          </div>
          <div v-if="!slotProps.row.enabled_security_features" style="font-family: 'VWText Bold">
            N/A
          </div>
        </template>
         <template v-slot:downloadSlot="slotProps">
           <button style="font-size: 14px; padding: 7px 15px;" @click.stop="downloadReport(slotProps.row)">Download</button>
        </template>
        </data-table>
        <div class="row-container" v-if="results.length > 0">
            <BasePagination
              :current-page="currentPage"
              :page-count="pageCount"
              class="articles-list__pagination"
              @nextPage="pageChangeHandle('next')"
              @previousPage="pageChangeHandle('previous')"
              @loadPage="pageChangeHandle"
              />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DataTable from '@/components/DataTable.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Magnifier from '@/assets/icons/magnifier.svg';
import carrot from '@/assets/icons/carrot_up.svg';
import BasePagination from '@/components/BasePagination.vue';

export default {
  name: 'results',
  components: {
    DataTable,
    Magnifier,
    Multiselect,
    carrot,
    BasePagination,
  },
  data() {
    return {
      results: [],
      currentPage: 1,
      toggleColor: '#00B0F0',
      searchQuery: '',
      rowOptions: this.$store.state.rowOptions,
      loading: this.$loading.show(),
      filterOptions: this.$store.state.jobFilter,
      filterValue: '',
      jobFilter: '',
      rowCountDropValue: { label: 'Show 25', value: 25 },
      filterToggle: false,
      assessments: [],
      dealerCodes: [],
      assessmentFilterValue: '',
      dealerCodeFilterValue: '',
      jobCodeFilterValue: '',
      filters: {},
    };
  },
  methods: {
    getResults() {
      if (this.$store.state.results.length === 0) {
        this.$store.dispatch('getResults')
          .then((res) => {
            this.results = res;
            for (let i = 0; i < this.results.length; i += 1) {
              this.dealerCodes.push(this.results[i].dealer_code);
              this.assessments.push(this.results[i].assessment_name);
            }
            this.loading.hide();
          });
      } else {
        this.results = this.$store.state.results;
        for (let i = 0; i < this.results.length; i += 1) {
          this.dealerCodes.push(this.results[i].dealer_code);
          this.assessments.push(this.results[i].assessment_name);
        }
        this.loading.hide();
      }
    },
    toggleStatus(row) {
      if (row.status === 1) {
        // eslint-disable-next-line no-param-reassign
        row.status = 2;
      } else {
        // eslint-disable-next-line no-param-reassign
        row.status = 1;
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    applyFilters() {
      this.filterToggle = !this.filterToggle;
      this.filters = {
        assessment_name: this.assessmentFilterValue,
        dealer_code: this.dealerCodeFilterValue,
        job_codes: this.jobCodeFilterValue,
      };
    },
    clearFilters() {
      this.filters = {};
      this.assessmentFilterValue = '';
      this.dealerCodeFilterValue = '';
      this.jobCodeFilterValue = '';
      this.filterToggle = !this.filterToggle;
    },
    async pageChangeHandle(value) {
      switch (value) {
        case 'next':
          this.currentPage += 1;
          break;
        case 'previous':
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
      }
    },
    hide() {
      this.filterToggle = false;
    },
    downloadReport(row) {
      const loader = this.$loading.show();
      this.$store.dispatch('downloadResult', { resultID: row.id })
        .then((res) => {
          this.downloadedReport = res;
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(res.data)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${row.assessment_name}.csv`;
          hiddenElement.click();
          loader.hide();
        });
    },
  },
  computed: {
    resultsTableHeaders() {
      return this.$store.state.resultsTableHeaders;
    },
    jobCodes() {
      return this.$store.state.jobCodes;
    },
    assessmentList() {
      if (this.assessments.length > 0) {
        return this.assessments.filter(this.onlyUnique);
      }
      return [];
    },
    dealerCodeList() {
      if (this.dealerCodes.length > 0) {
        return this.dealerCodes.filter(this.onlyUnique);
      }
      return [];
    },
    pageCount() {
      const l = this.results.length;
      const s = this.rowCountDropValue.value;
      return Math.ceil(l / s);
    },
  },
  watch: {
    searchQuery(val) {
      if (val) {
        this.currentPage = 1;
      }
    },
    pageCount(val) {
      if (val === 1) {
        this.currentPage = 1;
      }
    },
  },
  filterValue(val) {
    if (val) {
      this.jobFilter = val.name;
    } else {
      this.jobFilter = '';
    }
  },
  created() {
    this.getResults();
  },
};
</script>

<style lang="scss" scoped>
.header-middle {
  display: flex;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.above-table {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-bottom: 25px;
}

.float-right {
  display: flex;
}

.suspicion-rectangle {
  height: 15px;
  width: 100%;
  background-color: #DFE4E8;
}

.filter {
  width: 190px;
  display: block;
  position: relative;
  text-align: left;
  border: 1px solid #e8e8e8;
  background: #ffffff;
}

.filter-box {
  position: absolute;
  padding: 20px;
  display: flex;
  box-shadow: 3px 3px 6px #00000029;
  border: 1px solid #DFE4E8;
  border-radius: 0px 3px 3px 3px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  z-index: 3;
  width: 600px;
  top: 38px;
  justify-content: space-between;
}
</style>
