<template>
  <div class="dashboard">
    <div class="header" v-if="$route.name === 'duplicate-preview'">
        <div class="header-text">This is a live preview of exactly what your users will see when they take this assessment. Any interactions you have with this preview will not be recorded.</div>
      <div style="display: flex;">
        <button style="width: 170px; margin-right: 25px;" @click="saveAssessmentFromPreview()">Save & Publish</button>
        <button class="alt-btn" style="width: 100px;" @click="closeAssessment()">Close</button>
      </div>
    </div>
    <form v-show="$route.name !== 'duplicate-preview'" class="main" action @submit.prevent="saveAssessment(), goToPreview()" v-if="newAssessment">
      <div style="font-size: 12px; margin-bottom: 40px;">
        <span @click="goToAssessments()" style="cursor: pointer;">Assessments</span> /
        <span style="font-family: VWText Bold; color: #00B0F0;">Duplicate Assessment</span>
      </div>
      <transition name="bounce">
       <div class="panel">
        <div class="panel-header">
          <div>Security Features</div>
          <carrot :class="securityFeaturesToggle ? 'carrot' : 'carrot-flipped'" @click="securityFeaturesToggle = !securityFeaturesToggle" />
        </div>
        <div class="panel-body" v-if="securityFeaturesToggle">
          <div class="security-container">
            <div class="security-options">
              <div v-for="(features, index) in securityFeatures" :key="features.key" class="options">
                <div v-if="features.status" style="width: 20px; margin-right: 17px;">
              <checkbox class="checked" @click="checkboxToggle(features.status, index)"/>
              </div>
              <div v-else class="unchecked" @click="checkboxToggle(features.status, index)" :v-model="features.status"></div>
              <div class="security-label">{{features.label}}</div>
              <div class="question-circle" v-tooltip="{
              content: features.description,
              }">?</div>
            </div>
            </div>
            <div class="panel-column" style="align-self: center; width: 50%;">
              <div style="margin-bottom: 25px;">Security Level: <span style="font-family: VWText Bold">{{ securityLevel.toUpperCase() }}</span></div>
              <vue-progress-bar
              ></vue-progress-bar>
            </div>
          </div>
        </div>
      </div>
      </transition>
      <div class="panel">
        <div class="panel-header">
          <div>Details</div>
          <carrot :class="detailsToggle ? 'carrot' : 'carrot-flipped'" @click="detailsToggle = !detailsToggle" />
        </div>
        <transition name="bounce">
        <div class="panel-body" v-if="detailsToggle">
          <div class="panel-label">Title*</div>
          <input style="width: 98%; padding-left: 15px;" required placeholder="Title" v-model="newAssessment.name"/>
          <div class="panel-description">Give this assessment a meaningful title.</div>
          <div class="panel-label">Job Role*</div>
         <Multiselect
          class="multiselect multiple"
          v-if="jobCodes"
          v-model="jobFiltersSelected"
          :options="jobCodes"
          track-by="id"
          label="name"
          placeholder="Job Role"
          :show-labels="false"
          :multiple="true"
          :close-on-select="false"
          :searchable="true"
          :limit="5"
        ></Multiselect>
          <div class="panel-description">Select the job role(s) this assessment is for.</div>
          <div class="panel-label">Suggested Time Limit*</div>
          <clock class="clock-icn"></clock>
          <input class="time-input" v-model="newAssessment.time_limit" pattern="^\d*\.?\d*$" @input="timeLimitValidation" minlength="1" placeholder="3.5">
          <span style="margin-left: -70px; position: relative;"> hours</span>
          <div
            class="panel-description"
          >Set a suggested time limit for this assessment. Users will have the ability to take as much time as they need, but the more they go over this limit, the higher their suspicion score will be.</div>
          <div class="panel-label">Completion Deadline</div>
          <calendar class="calendar-icn"></calendar>
          <v-date-picker
            v-model="deadlineDate"
            :popover="{ placement: 'right', visibility: 'click'}"
            :min-date='new Date()'
            :input-props='{
            class: "w-full",
            placeholder: "01/01/20",
            readonly: true, }'
            :masks="{ input: ['MM/DD/YY']}"
          />
          <div class="panel-description">
            If you would like, set a date by which users will have to complete the assessment.
          </div>
        </div>
        </transition>
      </div>
      <div class="panel">
        <div class="panel-header">
          <div>Questions</div>
          <carrot :class="questionsToggle ? 'carrot' : 'carrot-flipped'" @click="questionsToggle = !questionsToggle"/>
        </div>
        <transition name="bounce">
        <div class="panel-body" style="margin-bottom: 35px;" v-if="questionsToggle">
          <div v-for="(question, index1) in newAssessment.questions" :key="question.id" class="question">
            <div class="panel-row" style="margin-bottom: 0px;">
              <div class="panel-column order">
                <span class="panel-label">Order</span>
                <input class="order-block" min-length="1" required pattern="\d+" :placeholder=(index1+1) v-model="question.sequence"/>
              </div>
              <div class="panel-column">
                <span class="panel-label">Question</span>
                <input required placeholder="Enter question text here" v-model="question.text" />
              </div>
              <carrot :class="question.open ? 'carrot' : 'carrot-flipped'" style="margin: 10px 0px 0px 60px; align-self: center" @click="question.open = !question.open; forceRefresh()"></carrot>
            </div>
            <div class="panel-row-container" style="margin-top: 25px;" v-if="question.open">
            <div class="panel-row" v-if="wordingVariants">
              <div class="panel-column">
                <span class="panel-label">Wording Variant</span>
                <input placeholder="Enter question text here" v-model="question.text_varient" />
              </div>
               <span style="width: 90px"></span>
            </div>
            <div v-for="(answer, index) in question.answers" :key="index" class="panel-row" style="position: relative;">
              <div :class="answer.correct ? 'radio-selected' : 'radio'" @click="selectAnswer(index1, index)">
                <div v-if="answer.correct" class="selected"></div>
              </div>
              <input required :placeholder="'Answer ' + (index + 1)" v-model='answer.text'/>
              <deleteIcn v-if="index !== 1 && index !== 0" class="delete-icn answer" @click="removeAnswer(index1, index)"></deleteIcn>
              <span style="width: 90px"></span>
            </div>
             <div class="panel-row" style="margin-bottom: 0; justify-content: space-between;">
               <div class="panel-column;" style="cursor: pointer;" @click="addAnswer(index1)">
              <span class="new-answer-circle"></span>
              <span style="color: #000000; opacity:.5;">Add response</span>
              </div>
              <div style="color: #FF335C; font-family: 'VWText Bold'; cursor: pointer;" @click="removeQuestion(index1)">
                <deleteIcn class="delete-icn"></deleteIcn> Remove Question</div>
            </div>
            </div>
          </div>
          <div style="color: #00B0F0; font-family: 'VWText Bold'; cursor: pointer;" @click="addQuestion()">
            <plusIcn class="plus-icn"></plusIcn> Add question
          </div>
        </div>
        </transition>
        <div style="display: flex;">
          <button value="saveAssessment" @click="saveClicked = true" style="margin-right: 10px;">Save</button>
          <button class="alt-btn" @click="previewClicked = true">Preview & Publish</button>
        </div>
      </div>
    </form>
    <preview
    v-if="$route.name === 'duplicate-preview'"
    :currentAssessment="newAssessment"
     @saveFromPreview="saveAssessmentFromPreview()"/>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import checkbox from '@/assets/icons/checkbox.svg';
import carrot from '@/assets/icons/carrot_up.svg';
import clock from '@/assets/icons/clock.svg';
import calendar from '@/assets/icons/calendar.svg';
import deleteIcn from '@/assets/icons/delete-icn.svg';
import plusIcn from '@/assets/icons/plus-icn.svg';
import Preview from '@/views/Preview.vue';

const securityFeatures = [
  {
    id: 1,
    label: 'Randomized question order',
    key: 'random',
    status: false,
    description: 'Assessment questions will appear in random order for each individual user. In this way, assessments have variations between testers.',
  },
  {
    id: 2,
    label: 'Periodic facial recognition',
    key: 'periodic',
    status: false,
    description: 'Throughout an assessment, the system will periodically capture photos of the user, provide comparison analysis, and flag when differences are detected. Selecting this option will automatically trigger a facial recognition warning upon completion of this assessment for all users on Internet Explorer 11, as IE 11 does not support Flash, which is required for the webcam tool to detect a change in facial recognition. According to Microsoft, they are removing support of Flash for IE on December 31, 2020.',
  },
  {
    id: 3,
    label: 'IP address monitoring',
    key: 'ipAddress',
    status: false,
    description: 'Throughout an assessment, the system will track and cross-check the IP address at which the assessment is taken and flag when differences are detected.',
  },
  {
    id: 4,
    label: 'Wording variants',
    key: 'wording',
    status: false,
    description: 'This allows assessment questions to have one additional wording variant. Users taking the assessment will then be presented with the same question, worded differently. In this way, assessments have variations between testers.',
  },
  {
    id: 5,
    label: 'Intermittent security questions',
    key: 'intermittent',
    status: false,
    description: 'Randomly throughout the assessment, a user will be asked to answer security questions to verify their identity. They will have provided these answers upon initially setting up their Secure Testing profile.',
  },
  {
    id: 6,
    label: 'Email and phone authentication',
    key: 'auth',
    status: false,
    description: 'Upon assessment initiation, a user will be asked to input a two-factor verification code. They will receive this code via email or text to the email or phone number they provided upon initially setting up their Secure Testing profile.',
  },
  {
    id: 7,
    label: 'Device monitoring',
    key: 'device',
    status: false,
    description: 'Throughout an assessment, the system will track the user’s utilized device. In this way, the system can determine if they are taking assessments on different devices or always using the same device.',
  },
];

export default {
  name: 'duplicate',
  components: {
    checkbox,
    carrot,
    Multiselect,
    clock,
    deleteIcn,
    calendar,
    plusIcn,
    Preview,
  },
  data() {
    return {
      jobFiltersSelected: [],
      newAssessment: null,
      securityFeaturesToggle: true,
      detailsToggle: true,
      questionsToggle: true,
      deadlineDate: null,
      newDate: this.$moment.utc(new Date()).format('MM/DD/YY'),
      filterValue: '',
      securityFeatures,
      loading: this.$loading.show(),
      saveClicked: false,
      previewClicked: false,
      interval: null,
    };
  },
  methods: {
    getAssessment() {
      this.$store.dispatch('getAssessments', { assessmentID: this.$store.state.assessmentSelected.id })
        .then((res) => {
          if (res) {
            this.newAssessment = JSON.parse(JSON.stringify(res));
            const date = this.$moment.utc(res.deadline_date).format('YYYY, MM, DD');
            this.deadlineDate = new Date(date);
            if (res.security_features.length > 0) {
              for (let i = 0; i < res.security_features.length; i += 1) {
                this.securityFeatures[res.security_features[i].security_feature_id - 1].status = true;
              }
            }
            if (res.job_codes.length > 0) {
              for (let i = 0; i < res.job_codes.length; i += 1) {
                this.jobFiltersSelected.push(res.job_codes[i]);
              }
            }
            this.newAssessment.questions.sort(this.getSortOrder('sequence'));
            for (let i = 0; i < this.newAssessment.questions.length; i += 1) {
              this.newAssessment.questions[i].sequence = i + 1;
              this.newAssessment.questions[i].open = true;
              this.newAssessment.questions[i].questionAnswered = true;
              for (let j = 0; j < this.newAssessment.questions[i].answers.length; j += 1) {
                this.newAssessment.questions[i].answers[j].selected = 0;
              }
            }
            this.loading.hide();
          }
        });
    },
    getSortOrder(prop) {
      // eslint-disable-next-line func-names
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    addQuestion() {
      let newOrder = null;
      if (!this.newAssessment.questions[this.newAssessment.questions.length - 1].sequence) {
        newOrder = 1;
      } else {
        newOrder = this.newAssessment.questions[this.newAssessment.questions.length - 1].sequence + 1;
      }
      const newId = this.newAssessment.questions[this.newAssessment.questions.length - 1].id + 1;
      this.newAssessment.questions.push({
        sequence: newOrder,
        id: newId,
        text: '',
        text_varient: '',
        open: true,
        answers: [
          {
            text: '',
            correct: 0,
          },
          {
            text: '',
            correct: 0,
          },
          {
            text: '',
            correct: 0,
          },
          {
            text: '',
            correct: 0,
          },
        ],
      });
    },
    addAnswer(question) {
      this.newAssessment.questions[question.id].answers.push({
        text: '',
        correct: 0,
      });
    },
    removeQuestion(question) {
      this.newAssessment.questions.splice(question, 1);
      for (let i = 0; i < this.newAssessment.questions.length; i += 1) {
        this.newAssessment.questions[i].sequence = i + 1;
      }
    },
    removeAnswer(id, answer) {
      this.newAssessment.questions[id].answers.splice(answer, 1);
    },
    checkboxToggle(status, index) {
      this.securityFeatures[index].status = !status;
      this.$Progress.set(34);
    },
    selectAnswer(id, answer) {
      for (let i = 0; i < this.newAssessment.questions[id].answers.length; i += 1) {
        if (this.newAssessment.questions[id].answers[i] === this.newAssessment.questions[id].answers[answer]) {
          this.newAssessment.questions[id].answers[i].correct = 1;
          this.newAssessment.questions[id].questionAnswered = true;
          this.$forceUpdate();
        } else {
          this.newAssessment.questions[id].answers[i].correct = 0;
          this.$forceUpdate();
        }
      }
    },
    validationCheck() {
      if (!this.jobRoleValidation) {
        this.$notify({
          text: 'One or more job roles must be selected',
          group: 'primary',
          type: 'error',
        });
        return false;
      }
      if (!this.answerValidation()) {
        this.$notify({
          text: 'One or more questions do not have a correct answer.',
          group: 'primary',
          type: 'error',
        });
        return false;
      }
      return true;
    },
    saveAssessmentFromPreview() {
      this.saveClicked = true;
      this.saveAssessment();
    },
    saveAssessment() {
      if (this.saveClicked === true) {
        const securityIdArray = [];
        const jobRoleArray = [];
        for (let i = 0; i < this.securityFeatures.length; i += 1) {
          if (this.securityFeatures[i].status === true) {
            securityIdArray.push(this.securityFeatures[i].id);
          }
        }
        for (let i = 0; i < this.jobFiltersSelected.length; i += 1) {
          jobRoleArray.push(this.jobFiltersSelected[i].id);
        }
        if (!this.validationCheck()) {
          this.saveClicked = false;
          return;
        }
        this.newAssessment.job_codes = jobRoleArray;
        this.newAssessment.security_features = securityIdArray;
        this.newAssessment.deadline_date = this.$moment.utc(this.deadlineDate).format('MM/DD/YY');
        const loader = this.$loading.show();
        this.$store.dispatch('createAssessment', { assessment: this.newAssessment })
          .then((res) => {
            if (res.data) {
              this.$router.push({
                name: 'success',
              });
            }
            this.$store.dispatch('getAssessments', { assessmentID: '' });
            loader.hide();
            this.$store.commit('SET_DASHBOARD_NAV', true);
          })
          .catch(() => {
            this.$notify({
              text: 'Something went wrong.',
              group: 'primary',
              type: 'error',
            });
            loader.hide();
          });
      }
    },
    forceRefresh() {
      this.$forceUpdate();
    },
    goToAssessments() {
      this.$router.push({
        name: 'assessments',
      });
    },
    goToPreview() {
      if (!this.validationCheck()) {
        this.previewClicked = false;
        return;
      }
      if (this.previewClicked === true) {
        this.$store.commit('SET_PREVIOUS_ROUTE', 'duplicate');
        this.$store.commit('SET_DASHBOARD_NAV', false);
        this.$router.push({
          name: 'duplicate-preview',
        });
      }
    },
    timeLimitValidation() {
      const maxChars = 3;
      if (this.newAssessment.time_limit === 0) {
        this.newAssessment.time_limit = '';
      }
      if (this.newAssessment.time_limit.length >= maxChars) {
        this.newAssessment.time_limit = this.newAssessment.time_limit.slice(0, maxChars);
      }
    },
    answerValidation() {
      if (this.questions.length > 0) {
        for (let i = 0; i < this.newAssessment.questions.length; i += 1) {
          if (!this.newAssessment.questions[i].questionAnswered) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    closeAssessment() {
      this.previewClicked = false;
      const oldRoute = this.$store.state.previousRoute;
      this.$store.commit('SET_PREVIOUS_ROUTE', 'duplicate-preview');
      this.$store.commit('SET_DASHBOARD_NAV', true);
      this.$router.push({
        name: oldRoute,
      });
    },
    refreshToken() {
      this.$store.dispatch('refresh');
    },
  },
  computed: {
    securityFeaturesChecked() {
      let numberChecked = 0;
      if (this.securityFeatures) {
        for (let i = 0; i < this.securityFeatures.length; i += 1) {
          if (this.securityFeatures[i].status) {
            numberChecked += 1;
            this.$Progress.set(numberChecked / 7 * 100);
          }
        }
      }
      return numberChecked;
    },
    securityLevel() {
      if (this.securityFeaturesChecked && this.securityFeaturesChecked < 3) {
        this.$Progress.setColor('#E4002C');
        return 'Low';
      }
      if (this.securityFeaturesChecked && this.securityFeaturesChecked < 6 && this.securityFeaturesChecked > 2) {
        this.$Progress.setColor('#FFD100');
        return 'Medium';
      }
      if (this.securityFeaturesChecked && this.securityFeaturesChecked > 5) {
        this.$Progress.setColor('#00872B');
        return 'High';
      }
      this.$Progress.set(1);
      this.$Progress.setColor('#E4002C');
      return 'Low';
    },
    jobRoleValidation() {
      if (this.jobFiltersSelected.length > 0) {
        return true;
      }
      return false;
    },
    wordingVariants() {
      return (this.securityFeatures[3].status);
    },
    questions() {
      return this.newAssessment.questions;
    },
    jobCodes() {
      if (this.$store.state.jobCodes) {
        return this.$store.state.jobCodes;
      }
      return null;
    },
  },
  created() {
    if (this.$store.state.assessmentSelected && this.$store.state.assessmentSelected.id) {
      this.getAssessment();
    } else {
      this.loading.hide();
      this.$router.push({
        name: 'dashboard',
      });
    }
    if (this.$store.previousRoute !== 'preview') {
      this.securityFeatures = [
        {
          id: 1,
          label: 'Randomized question order',
          key: 'random',
          status: false,
          description: 'Assessment questions will appear in random order for each individual user. In this way, assessments have variations between testers.',
        },
        {
          id: 2,
          label: 'Periodic facial recognition',
          key: 'periodic',
          status: false,
          description: 'Throughout an assessment, the system will periodically capture photos of the user, provide comparison analysis, and flag when differences are detected. Selecting this option will automatically trigger a facial recognition warning upon completion of this assessment for all users on Internet Explorer 11, as IE 11 does not support Flash, which is required for the webcam tool to detect a change in facial recognition. According to Microsoft, they are removing support of Flash for IE on December 31, 2020.',
        },
        {
          id: 3,
          label: 'IP address monitoring',
          key: 'ipAddress',
          status: false,
          description: 'Throughout an assessment, the system will track and cross-check the IP address at which the assessment is taken and flag when differences are detected.',
        },
        {
          id: 4,
          label: 'Wording variants',
          key: 'wording',
          status: false,
          description: 'This allows assessment questions to have one additional wording variant. Users taking the assessment will then be presented with the same question, worded differently. In this way, assessments have variations between testers.',
        },
        {
          id: 5,
          label: 'Intermittent security questions',
          key: 'intermittent',
          status: false,
          description: 'Randomly throughout the assessment, a user will be asked to answer security questions to verify their identity. They will have provided these answers upon initially setting up their Secure Testing profile.',
        },
        {
          id: 6,
          label: 'Email and phone authentication',
          key: 'auth',
          status: false,
          description: 'Upon assessment initiation, a user will be asked to input a two-factor verification code. They will receive this code via email or text to the email or phone number they provided upon initially setting up their Secure Testing profile.',
        },
        {
          id: 7,
          label: 'Device monitoring',
          key: 'device',
          status: false,
          description: 'Throughout an assessment, the system will track the user’s utilized device. In this way, the system can determine if they are taking assessments on different devices or always using the same device.',
        },
      ];
    }
  },
  beforeMount() {
    this.interval = window.setInterval(() => {
      this.refreshToken();
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>

input{
  padding: 10px;
  width: 100%;
}

.header-middle {
  display: flex;
}

.panel-row-container{
  margin-left: 60px;
  display: flex;
  flex-direction: column;
}

.answer {
  position: absolute;
  right: 50px;
  cursor: pointer;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.above-table {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-bottom: 25px;
}

.question-circle {
  color: #001E50;
  padding: 1px 6px 2px 6px;
  height: 15px;
  border-radius: 100px;
  background-color: #00B0F0;
  font-family: "VWHead Bold";
  text-align: center;
  margin-left: 8px;
  margin-top: 4px;
  font-size: 13px;
  cursor: default;
}

.float-right {
  display: flex;
}

.unchecked{
  padding: 10px 10px;
  border: 1px solid #DFE4E8;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 2px;
}

.checked {
  height: 20px;
  width: 100%;
  margin-right: 15px;
  cursor: pointer;
}
.new-answer-circle{
    padding: 0px 10px;
    border-radius: 50px;
    border: 1px solid #DFE4E8;
    margin-right: 15px;
}

.calendar-container{
  display: flex;
}

.selected {
  padding: 5px;
  border: 6px solid #00B0F0 !important;
  border-radius: 50px;
}

// .bounce-enter-active {
//   animation: bounce-in .5s;
// }
// .bounce-leave-active {
//   animation: bounce-in .5s reverse;
// }
// @keyframes bounce-in {
//   0% {
//     transform: scaleY(0);
//     transform: margin-bottom 0;
//   }
//   100% {
//     transform: scaleY(1);
//     transform: margin-bottom 100%;
//   }
// }

.header {
  background-color: #001E50;
  justify-content: space-between;
  padding: 35px 60px;
}
.header-text{
  font-family: 'VWText Bold';
  font-size: 14px;
  color: white;
}
</style>
